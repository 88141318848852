import React from "react";
import { useTranslation } from 'react-i18next';

const ArtistsGroup = ({ datas, title }) => {
  const {t, i18n} = useTranslation();

  return (
    <div className={`h-full ${i18n.language === "en" ? "font-DoHyeon-Regular" : "font-Khyay-Regular"}`}>
      <div className="h-auto md:justify-center md:items-center flex flex-col text-base md:text-xl font-bold border-b-2 w-5/6 mx-auto pb-2">
        <h1>{t(title)}</h1>
      </div>
      <div className="flex flex-row flex-wrap mx-1 my-3 justify-center">
        {datas.map((item) => (
          <div
            className="text-center h-full w-32 md:w-40 m-1 md:m-2 lg:m-4 flex flex-col items-center"
            key={item.id}
          >
            <img
              className="h-20 w-20 md:h-36 md:w-36 lg:h-40 lg:w-40 rounded-full mx-1 border-2 border-black hover:shadow-[0_-15px_45px_-10px_rgba(0,0,0,0.4)]"
              src={item.image}
              alt=""
            />
            <p className="text-sm md:text-base">{t(item.name)}</p>
            <p className="text-xs md:text-sm">{t(item.info)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArtistsGroup;
