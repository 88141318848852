import { useTranslation } from "react-i18next";

const About = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`h-full w-[97%] md:w-[90%] flex flex-col justify-center items-center mx-auto`}
    >
      <div className={`text-center my-3 md:my-4 py-1`}>
        <h1
          className={`text-2xl ${
            i18n.language === "en"
              ? "md:text-4xl leading-5 md:leading-7"
              : "md:text-3xl leading-9 md:leading-[3rem]"
          }`}
        >
          {t("founded")}
        </h1>
        <h2
          className={`text-base ${
            i18n.language === "en"
              ? "md:text-2xl leading-4 md:leading-6"
              : " md:text-xl leading-5 md:leading-6"
          }`}
        >
          {t("established")}
        </h2>
      </div>
      <p
        className={`text-left ${
          i18n.language === "en"
            ? "text-lg md:text-3xl leading-5 md:leading-9"
            : "text-base md:text-2xl"
        }`}
      >
        {t("about")}
      </p>
    </div>
  );
};

export default About;
