import React from "react";
import pyaytioo from "../assets/pyaytioo.webp";
import yeaung from "../assets/yeaung.webp";
import lwinmoe from "../assets/lwinmoe.webp";
import khantsithu from "../assets/khantsithu.webp";
import chanminyehtut from "../assets/chanminyehtut.webp";
import sisnaing from "../assets/sisnaing.webp";
import tintinhtun from "../assets/tintinhtun.webp";
import soemyatthuzar from "../assets/soemyatthuzar.webp";
import myatkaythiaung from "../assets/myatkaythiaung.webp";
import nansuoo from "../assets/nansuoo.webp";
import wuthmoneshweyee from "../assets/wuthmoneshweyee.webp";
import phwayphway from "../assets/phwayphway.webp";
import minoo from "../assets/minoo.webp";
import khinghtoothar from "../assets/khinghtoothar.webp";
import naydana from "../assets/naydana.webp";
import zarti from "../assets/zarti.webp";
import ArtistsGroup from "./ArtistsGroup";

const PhotoActor = () => {
  
  const data = [
    {
      id: 1,
      image: pyaytioo,
      name: "Pyay Ti Oo",
      info: "(Academy Award Winner)",
    },
    { id: 2, image: yeaung, name: "Ye Aung", info: "" },
    { id: 3, image: lwinmoe, name: "Lwin Moe", info: "" },
    { id: 4, image: khantsithu, name: "Khant Si Thu", info: "" },
    { id: 5, image: chanminyehtut, name: "Chan Min Ye Htut", info: "" },
    { id: 6, image: sisnaing, name: "Sitt Naing", info: "" },
    { id: 7, image: tintinhtun, name: "Tin Tin Htun", info: "" },
    { id: 8, image: soemyatthuzar, name: "Soe Myat Thu Zar", info: "" },
    { id: 9, image: myatkaythiaung, name: "Myat Kay Thi Aung", info: "" },
    { id: 10, image: nansuoo, name: "Nan Su Oo", info: "" },
    {
      id: 11,
      image: wuthmoneshweyee,
      name: "Wut Hmone Shwe Yee",
      info: "(Academy Award Winner)",
    },
    {
      id: 12,
      image: phwayphway,
      name: "Phway Phway",
      info: "(Academy Award Winner)",
    },
    { id: 13, image: minoo, name: "Min Oo", info: "" },
    { id: 14, image: khinghtoothar, name: "Khing Htoo Thar", info: "" },
    { id: 15, image: naydana, name: "Nay Da Na", info: "" },
    { id: 16, image: zarti, name: "Zarti", info: "" },
  ];
  return (
    <ArtistsGroup
      datas={data}
      key={data.id}
      title='Actors'
    />
  );
};

export default PhotoActor;
