import React, { useState } from "react";
import Navbar from "./components/Navbar";
import About from "./components/About";
import CoProduce from "./components/Coproduce";
import Series from "./components/Series";
import PhotoActor from "./components/PhotoActor";
import PhotoDirector from "./components/PhotoDirector";
import WatchTab from "./components/WatchTab";
import Contact from "./components/Contact";
import Home from "./components/Home";
import ComingSoon from "./components/ComingSoon";
import Movies from "./components/Movies";
import { Waypoint } from "react-waypoint";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  const [activeSection, setActiveSection] = useState("Home");
  const [onEnter, SetOnEnter] = useState(false);
  // console.log(activeSection);
  // console.log(slide);

  // useEffect(() => {
  //   console.log(slide);
  // }, [slide]);

  const handleWaypointEnter = (section) => {
    if (
      section === "CoProduced" ||
      section === "ComingSoon" ||
      section === "Movies" ||
      section === "Series" ||
      section === "WatchTab"
    ) {
      setActiveSection("Library");
    } else {
      setActiveSection(section);
    }
    // console.log("active section changed from Appjs: "+section)
  };

  return (
    <div
      className={`flex flex-col h-screen ${
        i18n.language === "en" ? "font-DoHyeon-Regular" : "font-Khyay-Regular"
      }`}
    >
      <Navbar
        onEnter={onEnter}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />
      <div className="snap-y snap-mandatory scroll-smooth overflow-y-scroll overflow-x-hidden">
        <section className="snap-center h-full w-full" id="Home">
          <Waypoint
            onEnter={() => {
              handleWaypointEnter("Home");
              SetOnEnter(false);
            }}
          />
          <Home />
        </section>
        <section className="snap-start md:h-full py-14 md:pt-20" id="About">
          <Waypoint
            onEnter={() => {
              handleWaypointEnter("About");
              SetOnEnter(true);
            }}
          />
          <About />
        </section>
        <section className="snap-center h-full pt-14 md:pt-20 pb-16 md:pb-0" id="CoProduced">
          <Waypoint
            onEnter={() => handleWaypointEnter("CoProduced")}
          />
          <CoProduce/>
        </section>
        <section className="snap-center h-full pt-14 md:pt-20 pb-16 md:pb-0" id="ComingSoon">
          <Waypoint
            onEnter={() => handleWaypointEnter("ComingSoon")}
          />
          <ComingSoon />
        </section>
        <section className="snap-center h-full pt-14 md:pt-20 pb-16 md:pb-0" id="Movies">
          <Waypoint
            onEnter={() => handleWaypointEnter("Movies")}
          />
          <Movies />
        </section>
        <section className="snap-center h-full pt-14 md:pt-20 pb-16 md:pb-0" id="Series">
          <Waypoint
            onEnter={() => handleWaypointEnter("Series")}
          />
          <Series />
        </section>
        <section className="snap-start pt-14 pb-24 md:pt-20" id="WatchNow">
          <Waypoint onEnter={() => handleWaypointEnter("WatchTab")} />
          <WatchTab />
        </section>
        <section className="snap-start pt-14 md:pt-20" id="Artists">
          <Waypoint onEnter={() => handleWaypointEnter("Artists")} />
          <PhotoActor />
        </section>
        <section className="snap-start pt-14 md:pt-20">
          <Waypoint onEnter={() => handleWaypointEnter("Artists")} />
          <PhotoDirector />
        </section>
        <section className="snap-start pt-14 md:mt-20" id="Contact">
          <Waypoint onEnter={() => handleWaypointEnter("Contact")} />
          <Contact />
        </section>
      </div>
    </div>
  );
}

export default App;
