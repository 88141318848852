import React from "react";

const Card = ({ image, artists, directors, url }) => {
  return (
    <div className="m-2 h-auto hover:scale-105 active:scale-100 duration-500">
      <div className="w-[300px] h-[170px] md:w-[408px] md:h-[230px]">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img
            className="rounded-lg h-full w-full object-center"
            src={image}
            alt=""
          />
        </a>
      </div>
      <div className="h-20 w-full hidden md:block shadow-md rounded-lg">
        <div className="p-2">
          <div className="w-full flex">
            <p className="font-bold pr-2">Director(s):</p>
            <div className="text-sm">
              {directors.map((director, index) => (
                <span key={index}>{director} </span>
              ))}
            </div>
          </div>
          <div className="w-full flex">
            <p className="font-bold pr-2">Actors:</p>
            <div className="text-sm">
              {artists.map((artist, index) => (
                <span key={index}>{artist} </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
