import { Carousel } from "flowbite-react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import PopupModal from "./PopupModal";
import { useState, useEffect } from "react";

function Swiper({ data }) {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [slideLocal, setSlideLocal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    const newWidth = window.innerWidth;
    setWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
  }, []);

  // console.log("width: " + width);

  const LeftButton = () => (
    <div className="bg-gray-300 h-14 w-14 md:h-16 md:w-16 flex justify-center items-center rounded-full opacity-50 hover:bg-gray-400">
      <MdOutlineKeyboardArrowLeft className="h-12 w-12 md:h-20 md:w-20" />
    </div>
  );
  const RightButton = () => (
    <div className="bg-gray-300 h-14 w-14 md:h-16 md:w-16 flex justify-center items-center rounded-full opacity-50 hover:bg-gray-400">
      <MdOutlineKeyboardArrowRight className="h-12 w-12 md:h-20 md:w-20" />
    </div>
  );

  return (
    <div className="h-[80%] md:h-[780px] m-1 md:m-4 md:mb-1">
      <Carousel
        leftControl={<LeftButton />}
        slideInterval={5000}
        // slide={}
        slide={slideLocal && !openModal}
        pauseOnHover={false}
        rightControl={<RightButton />}
      >
        {data.map((movie) => (
          <div className="h-full w-full" key={movie.id}>
            {/* Desktop Image */}
            {width >= 1024 && (
              <img
                className="h-full w-full object-center object-cover"
                src={movie.poster}
                alt=""
                onClick={() => {
                  setOpenModal(true);
                  setModalData(movie);
                }}
                onMouseEnter={() => {
                  setSlideLocal(true);
                }}
                onMouseLeave={() => {
                  setSlideLocal(false);
                }}
              />
            )}
            {/* Mobile Image */}
            {width < 1024 && (
              <img
                className="h-full w-full object-center object-cover"
                src={movie.poster_mobile}
                alt=""
                onClick={() => {
                  setOpenModal(true);
                  setModalData(movie);
                }}
                onMouseEnter={() => {
                  setSlideLocal(true);
                }}
                onMouseLeave={() => {
                  setSlideLocal(false);
                }}
              />
            )}
          </div>
        ))}
      </Carousel>
      {modalData && (
        <PopupModal
          openModal={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          data={modalData}
        />
      )}
    </div>
  );
}

export default Swiper;
