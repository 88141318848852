import Slider from "./Slider";
import {
  TarTaydata,
  ThabinThalMyaHninSi,
  AungMyatTharLinks1,
  BehindTheWallLinks,
  FilmLinks,
  MovieLinks,
} from "./utils/data";
import { useTranslation } from "react-i18next";

const WatchTab = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-full justify-center">
      <Slider links={FilmLinks} title={t(`myanmarMovie1`)} />
      <Slider links={MovieLinks} title={t(`myanmarMovie2`)}/>
      <Slider links={AungMyatTharLinks1} title={t(`myanmarMovie3`)}/>
      <Slider links={BehindTheWallLinks} title={t(`myanmarMovie4`)}/>
      <Slider links={ThabinThalMyaHninSi} title={t(`myanmarMovie5`)}/>
      <Slider links={TarTaydata} title={t(`myanmarMovie6`)}/>
    </div>
  );
};

export default WatchTab;
