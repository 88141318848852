import { Modal } from "flowbite-react";
function PopupModal({ openModal, onClose, data }) {
  return (
    <>
      <Modal show={openModal} onClose={onClose}>
        <Modal.Header>
          <p className="font-extrabold">{data.title}</p>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {!data.video && (
              <img
                className="rounded-lg h-full w-full overflow-hidden"
                src={data.poster}
                alt=""
              />
            )}
            {data.video && (
              <video
                width="1280"
                height="720"
                src={data.video}
                autoPlay
                muted
                loop
                playsInline
                controls
              />
            )}

            <div className="p-2">
              <div className="w-full flex">
                <p className="font-bold pr-2">Director(s):</p>
                <div className="text-sm">
                  {data.directors.map((director, index) => (
                    <span key={index}>{director} </span>
                  ))}
                </div>
              </div>
              <div className="w-full flex">
                <p className="font-bold pr-2">Actors:</p>
                <div className="text-sm">
                  {data.artists.map((artist, index) => (
                    <span key={index}>{artist} </span>
                  ))}
                </div>
              </div>
            </div>
            <p>{data.summary}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PopupModal;
