import React from "react";
import video from "../assets/reel2.mp4";
import { SlArrowDown } from "react-icons/sl";

function Home() {
  return (
    <div className="relative text-3xl flex justify-center items-center h-full w-full overflow-hidden">
      <video className="h-[140vh] w-full object-cover"  src={video} autoPlay loop muted playsInline />
      <a href="#About" className="absolute bottom-1 animate-bounce"><SlArrowDown className="size-12 md:size-16 text-white" /></a>
    </div>
  );
}

export default Home;
