import { React, useState, useRef } from "react";
import { IoPaperPlane } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const {t} = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4lul9el",
        "template_gpzprs6",
        e.target,
        "LwWQCpBgHR0RievP8"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="">
      <div className="h-auto md:w-[1000px] md:mx-auto md:h-80 m-2 flex flex-col md:flex-row md:justify-start items-center rounded-lg shadow-lg">
        <iframe
          className="h-full w-full md:w-1/2 p-2 rounded-xl"
          title="Google Map"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3819.951954112375!2d96.15824957621926!3d16.77906582004242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1ec85d4492bbd%3A0x7d7314bdc1c39b54!2sSEIN%20HTAY%20Film%20Production!5e0!3m2!1sen!2smm!4v1704879648966!5m2!1sen!2smm"
        />
        <div className="w-full md:w-auto p-2 mx-auto">
          <span className="text-xs md:text-lg">
            {t("address1")}
            <br />
            {t("address2")}
            <br />
            {t("address3")}
            <br />
            Tel: 01 8250 247,01 8380007 <br />
            Email: info@seinhtay.com
          </span>
        </div>
      </div>
      <div className="h-auto">
        <form
          className="max-w-screen-lg mx-auto p-2"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="flex flex-row">
            <IoPaperPlane className="h-8 w-8" />
            <h1 className="text-lg md:text-2xl font-bold">Contact Us</h1>
          </div>
          <label
            htmlFor="name"
            className="block my-2 text-base font-medium text-gray-900 dark:text-white"
          >
            Name
          </label>
          <input
            type="name"
            id="name"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
            placeholder="Your Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="from_name"
          />
          <label
            htmlFor="email"
            className="block my-2 text-base font-medium text-gray-900 dark:text-white"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
            placeholder="Your Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="from_email"
          />
          <label
            htmlFor="message"
            className="block my-2 text-base font-medium text-gray-900 dark:text-white"
          >
            Your message
          </label>
          <textarea
            id="message"
            rows="4"
            className="block p-2.5 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Leave a comment..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            name="message"
          />
          <div className="text-center">
            <input
              className="bg-red-600 p-2 mt-1 w-full text-white rounded-lg transition duration-300 hover:scale-[1.01] active:bg-red-700 active:scale-[0.99]"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
