import React from "react";
import { useTranslation } from "react-i18next"; // import { Trans } from 'react-i18next'

function LanguageToggle({ currLang, setCurrLang }) {
  const { i18n } = useTranslation();

  return (
    <div className="h-full flex justify-start items-center">
      {/* <div>
      <h1>{t('welcome')}</h1>
      <p>{t('description')}</p>
    </div> */}
      {/* <div className='text-white'>
      <button onClick={() => i18n.changeLanguage('en')}>English</button>
      <button onClick={() => i18n.changeLanguage('my')}>Myanmar</button>
    </div> */}
      <label className="relative inline-flex cursor-pointer">
        <input
          type="checkbox"
          value=""
          onClick={() => {
            if (currLang === "MM") {
              setCurrLang("EN");
              i18n.changeLanguage("en");
            } else {
              setCurrLang("MM");
              i18n.changeLanguage("my");
            }
          }}
          className="sr-only peer"
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-gray-200"></div>
        
      </label>
      <span className="ml-1 text-xs font-medium text-white">{currLang}</span>
    </div>
  );
}

export default LanguageToggle;
