import React from "react";
import mgmyomin from "../assets/mgmyomin.webp";
import kozaw from "../assets/kozaw.webp";
import vangyi from "../assets/vangyi.webp";
import minsatthit from "../assets/minsatthit.webp";
import aungchanlu from "../assets/aungchanlu.webp";
import kyawzawlin from "../assets/kyawzawlin.webp";
import thihaminnyi from "../assets/thihaminnyi.webp";
import nyominlwin from "../assets/nyominlwin.webp";
import shwezinminkyaw from "../assets/shwezinminkyaw.webp";
import kyawhninsi from "../assets/kyawhninsi.webp";
import ArtistsGroup from "./ArtistsGroup";

const PhotoDirector = () => {
  const data = [
    { id: 1, image: mgmyomin, name: "Mg Myo Min", info: "(Yin Thwin Phyit)" },
    { id: 2, image: kozaw, name: "Ko Zaw", info: "(Ar Yone Oo)" },
    { id: 3, image: vangyi, name: "Ban Gyi", info: "" },
    { id: 4, 
      image: minsatthit, 
      name: "Min Sat Thit", 
      info: "(Ko Set)" 
    },
    { id: 5, image: aungchanlu, name: "Aung Chan Lu", info: "" },
    {
      id: 6,
      image: kyawzawlin,
      name: "Kyaw Zaw Lin",
      info: "(A Thway Pone Yate)",
    },
    { id: 7, image: thihaminnyi, name: "Thiha Min Nyi", info: "" },
    { id: 8, image: nyominlwin, name: "Nyo Min Lwin", info: "(Academy Award Winner)" },
    {id: 9, image: shwezinminkyaw, name: "Shwe Zin Min Kyaw", info: "" },
    {id: 10, image: kyawhninsi, name:"Kyaw Hnin Si", info: ""}
  ];
  return (
    <ArtistsGroup
      datas={data}
      title='Directors'
    />
  );
};

export default PhotoDirector;
