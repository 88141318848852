import React, { useRef } from "react";
import Card from "./Card";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Slider = ({ links, title }) => {
  const sliderRef = useRef(null);
  const { i18n } = useTranslation();

  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 318;
    }
  };

  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 318;
    }
  };

  return (
    <div className="w-full h-full flex flex-col my-2">
      <h2 className={` bold ml-1 md:ml-4 mb-2 ${
        i18n.language === "en" ? "text-2xl" : "text-xl"
      }`}>{title}</h2>
      <div className="flex flex-row items-center overflow-hidden">
        <MdChevronLeft
          onClick={slideLeft}
          className="hidden md:block bg-white border text-black left-0 mx-2 rounded-full cursor-pointer z-10 shadow-lg hover:scale-105 transition duration-200"
          style={{ fontSize: "40px" }}
        />
        <div
          ref={sliderRef}
          className="w-full flex"
          style={{ overflowX: "auto", scrollbarWidth: "none", WebkitOverflowScrolling: "touch" }}
        >
          {links.map((movie) => (
            <Card
              key={movie.id}
              image={movie.poster}
              artists={movie.artists}
              directors={movie.directors}
              url={movie.url}
            />
          ))}
        </div>
        <MdChevronRight
          onClick={slideRight}
          className="hidden md:block bg-white border text-black left-0 mx-2 rounded-full cursor-pointer z-10 shadow-lg hover:scale-105 transition duration-200"
          style={{ fontSize: "40px" }}
        />
      </div>
    </div>
  );
};

export default Slider;
