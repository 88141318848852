import Swiper from "./Swiper";
import { SeriesData } from "./utils/data";
import { useTranslation } from "react-i18next";

const Series = () => {
  const { i18n, t } = useTranslation();
  return (
    <div className="flex flex-col h-full justify-center">
      <Swiper data={SeriesData} />
      <h2
        className={`text-black text-center font-bold m-1 md:ml-4 my-2 ${
          i18n.language === "en"
            ? "text-3xl md:text-4xl"
            : "text-2xl md:text-3xl"
        }`}
      >
        {t("movieTitle4")}
      </h2>
    </div>
  );
};

export default Series;
